import React from 'react';
import {Link} from 'gatsby';
import useDarkMode from 'use-dark-mode';

const PostCard = (props) => {
  const {title, date, excerpt, link} = props;
  let initDarkMode = false;
  if (typeof window !== `undefined`) {
    initDarkMode = JSON.parse(localStorage.getItem('darkMode'))
  }
  const darkMode = useDarkMode(initDarkMode);

  return (
    <div className={darkMode.value ? 'postCard-dark' : 'postCard'}>
      <div className='link'>
        <Link to={link}>
          <h3>{title}</h3>
        </Link>
      </div>
      <h4>{date}</h4>
      <div>{excerpt}</div>
    </div>
  );
};

export default PostCard;
