import React from 'react';
import {graphql} from 'gatsby';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/postCard';

class Index extends React.Component {

  render() {
    const {data} = this.props;
    const blogTitle = data.site.siteMetadata.title;
    const blogPosts = data.allMarkdownRemark.edges;

    return (
      <Layout title={blogTitle}>
        <SEO title={blogTitle} />
        <div className='app-container'>
          <section>
            {blogPosts.map((post) => {
              const excerpt = post.node.excerpt;
              const {title, date} = post.node.frontmatter;
              const link = post.node.fields.slug;
              return <PostCard key={`postCard_${title}`} title={title} date={date} excerpt={excerpt} link={link} />;
            })}
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            title
            subtitle
            description
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
    profilePic: file(absolutePath: {regex: "/profilePic.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
